var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.info != ""
    ? _c("div", { staticClass: "Receivingprintdetails" }, [
        _c("div", { staticClass: "auto-table-flex" }, [
          _c(
            "div",
            { staticClass: "ctns" },
            [
              _c("div", { staticClass: "toptli" }, [
                _c("div", { staticClass: "divtitle" }, [
                  _c("div", { staticClass: "titletab" }, [
                    _vm._v("订单号：" + _vm._s(_vm.info.order_no)),
                  ]),
                  _c("div", { staticClass: "div" }, [
                    _c("div", { staticClass: "its" }, [
                      _vm._v("客户姓名：" + _vm._s(_vm.info.member.fullname)),
                    ]),
                    _c("div", { staticClass: "its" }, [
                      _vm._v("客户电话：" + _vm._s(_vm.info.member.mobile)),
                    ]),
                  ]),
                  _c("div", { staticClass: "div" }, [
                    _c("div", { staticClass: "its" }, [
                      _vm.role == "delivery"
                        ? _c("span", [
                            _vm._v(
                              "城市仓：" +
                                _vm._s(_vm.info.delivery.logistics.name)
                            ),
                          ])
                        : _c("span", [
                            _vm._v(
                              "城市仓：" +
                                _vm._s(_vm.info.store_delivery.logistics.name)
                            ),
                          ]),
                    ]),
                    _c("div", { staticClass: "its" }, [
                      _vm.role == "delivery"
                        ? _c("span", [
                            _vm._v(
                              "自提点信息：" +
                                _vm._s(_vm.info.delivery.name) +
                                "，" +
                                _vm._s(_vm.info.delivery.address)
                            ),
                          ])
                        : _c("span", [
                            _vm._v(
                              "自提点信息：" +
                                _vm._s(_vm.info.store_delivery.name) +
                                "，" +
                                _vm._s(_vm.info.store_delivery.address)
                            ),
                          ]),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "flexbox" }, [
                  _c("img", { attrs: { src: _vm.info.qrcode, alt: "" } }),
                  _c("div", [_vm._v("订单号：" + _vm._s(_vm.info.order_no))]),
                ]),
              ]),
              _c(
                "el-table",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    data: _vm.info.order_detail,
                    border: "",
                    "header-cell-style": { color: "#333333", fontSize: "16px" },
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      type: "index",
                      width: "100",
                      align: "center",
                      label: "序号",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "title",
                      label: "商品名称",
                      align: "center",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$empty.empty(scope.row.title)) +
                                  "(" +
                                  _vm._s(
                                    _vm.$empty.empty(scope.row.product.sku_code)
                                  ) +
                                  ") "
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1579270987
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "address", align: "center", label: "等级" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$empty.empty(scope.row.product.grade)
                                  ) +
                                  " "
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2837247526
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "address", align: "center", label: "毛重" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$empty.empty(scope.row.gross_weight)
                                  ) +
                                  " "
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2443653299
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "address", align: "center", label: "净重" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$empty.empty(scope.row.weight)) +
                                  " "
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      826962742
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "address",
                      align: "center",
                      label: "采购价",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.tool.toDecimal2(
                                      (scope.row.price * 10000) / 100 / 10000
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      478065695
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "address", align: "center", label: "数量" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$empty.empty(scope.row.qty)) +
                                  " "
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2557675082
                    ),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]),
        _c(
          "div",
          { staticClass: "btn" },
          [_c("el-button", { on: { click: _vm.fanhui } }, [_vm._v("返回")])],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }